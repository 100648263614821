<template>
    <div class="login" :style="{backgroundImage:'url('+loginBg+')'}">
        <img alt="icon" class="icon" :src="icon">
        <div class="loginBox">
            <div style="width:3.75rem;margin:.7813rem auto 0 auto;">
                <div class="title">体态博士管理系统</div>
                <div class="checkWay">
                    <div class="psdActive">重置密码</div>
                </div>
                <!--密码登录 -->
                <div class="password" v-if="isReset">
                    <el-form :model="passwordForm" :rules="rules" ref="passwordForm" label-width="0px">
                        <el-form-item prop="new">
                            <el-input
                                placeholder="请输入新密码"
                                prefix-icon="el-icon-lock"
                                show-password
                                v-model="passwordForm.new" style="margin-top:0.3125rem;width:3.75rem">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="newAgain">
                            <el-input
                                placeholder="请再次输入新密码"
                                prefix-icon="el-icon-lock"
                                show-password
                                v-model="passwordForm.newAgain" style="margin-top:0.3125rem;width:3.75rem">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="submit" @click="submit('passwordForm')">确定</div>
                    <div class="other">
                        <span @click="loginByPsw">返回密码登录</span>
                    </div>
                </div>
                <div class="resetOK" v-if="!isReset">
                    <img alt="设置成功" :src="successIcon" class="successImg">
                    <p class="successTitle">您已经成功设置密码，请试用新密码登录</p>
                    <div class="submit" @click="toLgin">马上登录</div>
                </div>
            </div>
        </div>
         <!-- ICP -->
        <div class="icp">备案号：ICP-xxxxxxxxxx</div>
    </div>
</template>
<script>
export default {
    name:"login",
    data(){
            //自定义校验
        var validatePass = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请输入密码'));
            } else {
            if (this.passwordForm.checkPass !== '') {
                this.$refs.passwordForm.validateField('checkPass');
            }
            callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请再次输入密码'));
            } else if (value !== this.passwordForm.new) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        };

    return{
            
            loginBg:'https://sz.drbodyhealth.com/loginBg.png',//更换为服务器上的图片
            
            icon:'https://sz.drbodyhealth.com/logo.png',
            
            successIcon:'https://sz.drbodyhealth.com/iconSuccess.png',//更换为服务器上的图片
            //psw
            passwordForm:{
                new:'',
                newAgain:'',
            },
            //resetOk
            isReset:true,
            //rules
            rules: {
                    new: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    newAgain: [
                        { validator: validatePass2, trigger: 'blur' }
                    ],
                    }
        }
    },
    methods:{
        loginByPsw(){
            this.$router.push({path:'/login'})
        },
        toLgin(){
            this.$router.push({path:'/login'})
        },
        //确认设置密码
        submit(formName){
            // 
            this.$refs[formName].validate((valid) => {
            if (valid) {
               //console.log("重置完成！")
            } else {
                //console.log('重置密码填写有误！');
                return false;
            }
            });
        },
        // 清空重填
            /*
                resetForm(formName) {
                    this.$refs[formName].resetFields();
                }
            */
    }
}
</script>
<style scoped lang="stylus">
@import '~@/assets/css/reset.css'
// component
.psdActive
    font-size: .1563rem
    font-family: Source Han Sans CN
    width: .7031rem
    color: #353535
    font-weight: bold
    text-align: center
    cursor pointer
    position relative
    &::before
        width: 100%
        height: 3px
        content: ""
        position: absolute
        border-radius: .0313rem
        left: 0
        bottom:  -.0313rem
        background-color: #00CCCC 
.login 
    width:100%
    height: 8.44rem
    overflow hidden
    background-size: cover
    background-repeat: no-repeat
    .icon 
        width: 1.1484rem
        height:.1875rem 
        margin: .2188rem 0 0 .3672rem
    .loginBox
        width: 4.6875rem
        height: 5.625rem
        margin-top: .78rem
        margin-left: 8.5469rem
        overflow hidden
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
        .title 
            font-size:.25rem
            color: #00CCCC
            font-weight: bold 
            line-height: .2813rem
        .checkWay
            margin-top: .4219rem
            display: flex
        .password
            .submit
                width: 3.75rem
                height: .4688rem
                background-color: #00CCCC
                margin-top: 1.0313rem
                font-size: .1563rem
                line-height: .4688rem
                color: #FFFFFF
                text-align: center
                border-radius: 2px
                cursor pointer
            .other
                margin-top: .1875rem
                width: 3.75rem
                display: flex
                justify-content: center
                span 
                    font-size: .1406rem
                    line-height: .2344rem
                    color: #0090FF
                    cursor pointer

        .resetOK
            text-align: center
            overflow hidden
            width: 3.75rem
            margin-top: .5rem
            .successImg
                width: .9375rem
                height: .9375rem
            .successTitle
                color: #353535
                font-size: .1406rem
            .submit 
                width: 3.75rem
                height: .4688rem
                background-color: #00CCCC
                margin-top: .6563rem
                font-size: .1563rem
                line-height: .4688rem
                color: #FFFFFF
                text-align: center
                border-radius: 2px
                cursor pointer
    .icp
        width: 1.56rem
        height: .11rem
        text-align: center
        font-size: .11rem
        color: #353535
        opacity .4
        line-height: .11rem
        margin: .78rem auto 0 auto

</style>